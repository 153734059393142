<template>
  <div class="outContainer">
    <div class="back">
      <i class="el-icon-back" @click="back"></i>
      <span>{{ tableData[0] ? tableData[0].sampleTypeName : '--'}}</span>
    </div>
    <div class="search">
        <el-button size="mini" @click="init" type="primary" icon="el-icon-refresh">刷新</el-button>
        <el-button size="mini" @click="handleAdd(null)">新增</el-button>
      </div>
    <div class="tableContainer">
        <el-table
         height="100%"
         :data="tableData">
         <el-table-column type="expand">
            <template slot-scope="scope">
                <el-table 
                  border
                  :header-cell-style="{ background:'#ddd' }"
                  :data="scope.row.standardsLevels">
                    <el-table-column align="center" label="等级" prop="level"></el-table-column>
                    <el-table-column align="center" label="颜色" prop="rgb">
                        <template slot-scope="props">
                            <span class="levelColor" :style="{ backgroundColor: `rgb(${props.row.rgb})` }"></span>
                            <p>{{ props.row.rgb }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="描述" prop="remark"></el-table-column>
                </el-table>
            </template>
         </el-table-column>
          <el-table-column align="center" label="流水线模板名称" prop="pipelineTemplateName"></el-table-column>
          <el-table-column align="center" label="样品最小重量（克）" prop="sampleMinWeight"></el-table-column>
          <el-table-column align="center" label="样品最大重量（克）" prop="sampleMaxWeight"></el-table-column>
          <el-table-column align="center" label="创建时间" prop="createTimeStr"></el-table-column>
          <el-table-column align='center' label="操作" fixed="right" width="300">
            <template slot-scope="scope">
                <el-button size="mini" @click="handleAdd(scope.row,'edit')">修改</el-button>
                <el-button size="mini" @click="handleDel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination v-bind:child-msg="searchInfo" @callFather="callFather"></pagination>
    </div>
    <!-- 新增检测项及其标准弹框 -->
    <el-dialog :visible.sync="testItemDialog" title="检测项及其标准" :modal="false" width="70%">
        <el-form ref="testItemForm" :model="testItemForm" label-position="right" status-icon label-width="180px" :rules="testItemRules" style="width:95%;overflow:auto">
          <el-form-item label="检测项" prop="pipelineTemplateId">
            <el-select v-model="testItemForm.pipelineTemplateId" placeholder="请选择" style="width:100%">
              <el-option
                v-for="item in pipelineList"
                :key="item.id"
                :label="item.name +  '-' + (item.fee ? item.fee + '元/次' : '暂无定价')"
                :value="item.id"
                >
                <span style="float: left">{{ item.name }}</span> <!-- 检测项名称 -->
                <!-- <span style="float: right; color: #8492a6; font-size: 13px">{{ item.fee ? item.fee + '元/次' : "暂无定价" }}</span> 费用 -->
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="样品克重范围（克）" prop="sampleMinWeight">
            <div class="weightRange">
              <el-input placeholder="请输入最小克重" type="number" v-model="testItemForm.sampleMinWeight" @input="handleInput('sampleMinWeight')"></el-input> 
              <el-col class="line" :span="2">~</el-col>
              <el-input placeholder="请输入最大克重" type="number" v-model="testItemForm.sampleMaxWeight" @input="handleInput('sampleMaxWeight')"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="颜色标准等级" prop="standardsLevels">
            <div class="colorStandard" v-for="(item,index) in testItemForm.standardsLevels" :key="index">
                <el-form :model="item" label-width="90px" style="display: flex;flex-wrap: wrap; margin-bottom: 20px;align-items: center;">
                    <p class="levelName">等级{{ index + 1 }}（等级大于合格等级越多超标越严重；颜色值范围是：0~255）</p>
                    <el-form-item label="颜色：" style="width:100%;display: flex;margin: 5px 0;align-items: center;">
                        <el-input placeholder="R（0~255）" type="number" v-model="item.R" style="flex: 1;"></el-input>
                        <el-input placeholder="G（0~255）" type="number" v-model="item.G" style="flex: 1;"></el-input>
                        <el-input placeholder="B（0~255）" type="number" v-model="item.B" style="flex: 1;"></el-input>
                        <span class="levelColor" :style="{ backgroundColor: `rgb(${item.R ? item.R : 0},${item.G ? item.G : 0},${item.B ? item.B : 0})` }"></span>
                    </el-form-item>
                    <el-form-item label="描述：" style="width: 100%">
                        <el-input placeholder="描述" type="text" v-model="item.remark"></el-input>
                    </el-form-item>
                </el-form>
                <i class="el-icon-delete" @click="deleteStandard(index)"></i>
            </div>
            <el-button  class="button-new-tag" size="small" @click="addStandard(index)" style="width:100%;border:dashed #c0c4cc 1px;">+ 添加梯度</el-button>
          </el-form-item>
          <el-form-item label="合格等级">
            <el-radio-group v-model="testItemForm.qualifiedLevel">
                <el-radio v-for="(item,index) in testItemForm.standardsLevels" :key="index" :label="index">等级{{ index + 1 }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="testItemDialog = false">取 消</el-button>
          <el-button type="primary" @click="confirmAddItem('testItemForm')">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import pagination from '@/components/Pagination.vue';
import { deleteStandards,getStandards,getPipelineTemplateList,addStandards,updateStandards } from '@/api/public.js';
import { timeToTime } from '@/api/publicFun.js';
export default {
  data() {
    return {
        searchInfo:{
            pageNo:1,
            pageSize:10,
            param:{
                sampleTypeId:null,
            }
        },
        tableData:[],
        sampleTypeId:null,
        testItemDialog:false,
        testItemForm:{
            standardsLevels:[],
        }, // 新增检测项表单
        testItemRules:{ // 新增检测项表单验证
          test: [
            { required: true, message: "请选择检测项", trigger: "change" },
          ],
          weight: [
            { required: true, message: "请输入样品标准克重", trigger: "blur" },
          ],
          standardColor: [
            { required: true, message: "请输入合格颜色", trigger: "blur" },
          ],
          unStandardColor: [
            { required: true, message: "请输入不合格颜色", trigger: "blur" },
          ],
          fee: [
            { required: true, message: "请输入检测费用", trigger: "blur" },
          ],
        },
        pipelineList:[], // 检测流水线列表
        row:null,
    }
  },

  components: {
    pagination
  },

  computed: {},

  mounted() {
    this.searchInfo.param.sampleTypeId = this.$route.query.sampleTypeId;
    this.init();
  },

  methods: {
    async init(){
        await getStandards(this.searchInfo).then(res=>{
            this.tableData = res.success ? res.data.list : [];
            this.tableData.forEach(element=>{
                element.createTimeStr = timeToTime(element.createTime,'seconds');
            })
        })
    },
    handleInput(value) {
        this.testItemForm[value] = this.testItemForm[value] ? Number(this.testItemForm[value]) : "";
    },
    // 动态加载颜色
    changeBackgroundColorBasedOnContent(element) {
        var value = element.textContent.trim().toLowerCase();
        // 根据内容的长度或其他逻辑来决定颜色，这里只是示例
        var color = '#' + Math.floor(Math.random()*16777215).toString(16);
        element.style.backgroundColor = color;
    },

    // 删除
    handleDel(row){
        this.$confirm('确认删除吗？').then(()=>{
            deleteStandards({param:row.id}).then(res=>{
                this.$message({
                    message:res.success ? '删除成功' : res.msg,
                    type:res.success ? 'success' : 'fail'
                })
                this.init();
            })
        })
    },
    // 获取检测流水列表
    async getPipeline(){
        await getPipelineTemplateList().then(res=>{
            this.pipelineList = res.success ? res.data : [];
        })
    },
    // 打开新增编辑弹框
    async handleAdd(row){
        this.row = row;
        this.getPipeline();
        if(row){
            this.testItemForm = {...row};
            this.testItemForm.standardsLevels.forEach(element => {
                let arr = element.rgb.split(',');
                this.$set(element, 'R', Number(arr[0]));
                this.$set(element, 'G', Number(arr[1]));
                this.$set(element, 'B', Number(arr[2]));
                element = {...element};
            })
            console.log(this.testItemForm,999)
        }
        this.testItemForm.sampleTypeId = this.searchInfo.param.sampleTypeId;
        this.testItemDialog = true;
    },
    // 新增颜色等级
    addStandard(){
        this.testItemForm.standardsLevels.push({
          level:this.testItemForm.standardsLevels.length+1,
        });
    },
    // 删除颜色等级
    deleteStandard(index){
        this.testItemForm.standardsLevels.splice(index,1);
    },
    // 确认新增/修改检测标准
    confirmAddItem(){
        this.$confirm('确认执行该操作吗？').then(async () => {
            this.testItemForm.standardsLevels.forEach(element=>{
                element.rgb = `${element.R},${element.G},${element.B}`;
                element.level = Number(element.level);
            })
            if(!this.row){
                await addStandards({param:this.testItemForm}).then(res=>{
                    this.$message({
                        message:res.success ? '提交成功' : res.msg ,
                        type:res.suucess ? 'success' : 'fail',
                    })
                    this.init();
                })
            }else{
                await updateStandards({param:this.testItemForm}).then(res=>{
                    this.$message({
                        message:res.success ? '提交成功' : res.msg ,
                        type:res.suucess ? 'success' : 'fail',
                    })
                    this.init();
                    this.testItemDialog = res.success  ? false : this.testItemDialog;
                })
            }
            
        })
    },

    // 分页插件事件
    callFather(parm) {
      this.searchInfo.pageNo = parm.currentPage;
      this.init();
    },
    // 返回上一页
    back(){
        this.$router.go(-1)
    },
  },
};
</script>
<style scoped lang="scss">
.levelName{
  text-align: center;
  width: 100%;
  color: rgb(93, 93, 240);
}
/deep/ .el-dialog{
  max-height: 80%;
  overflow-y: auto;
}
.levelColor{
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: solid 1px #000;
}

.weightRange{
 display: flex;
 justify-content: space-around;
}
.colorStandard{
    display: flex;
    width: 100%;
    align-items: center;
    /deep/ .el-form-item__content{
        margin-left: 0 !important;
        display: flex;
        align-items: center;
        flex: 1;
    }
    .el-form-item .el-form-item{
        flex: 1;
        display: flex;
        align-items: center;
        width: 50%;
        .el-form-item__label{
            width: 65px !important;
        }
    }
    .el-icon-delete{
        margin-left: 5px;
        color: red;
        cursor: pointer;
        font-size: 18px;
    }
}

</style>
